import { pick } from "lodash";

export default class AuthRequest {
    constructor(data = {}) {
        this.setData(data);
    }
    setData(data ={}){
        this.id = data?.id || "";
        this.email = data?.email || "";
        this.username = data?.username || "";
        this.password = data?.password || "";
        this.confirm_password = data?.confirm_password || "";
        this.role_id = data?.role_id || 1;
        this.avatar = data?.avatar || "";
        this.first_name = data?.first_name || "";
        this.last_name = data?.last_name || "";
        this.address = data?.address || "";
        this.token = data?.token || "";
        this.refresh_token = data?.refresh_token || ""; 
    }
    exportCreate(){
        return pick(this, ["email", "username", "password", "role_id", "first_name", "last_name", "address"]);
    }
}