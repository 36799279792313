import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/ParticipantType";
import * as Actions from "../actions/participantAction";
import * as Services from "../services/participantService";
import * as Func from "../../utils/functions"
import { SUCCESS_CODE } from "../../constants/define";
import { notification } from "antd";

function* createParticipant() {
    yield takeLatest(
        Types.CREATE_PARTICIPANT_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.createParticipant, action?.params)

            if (!SUCCESS_CODE.includes(data?.status)) {
                throw data;
            }

            console.log(data.metadata)

            yield put(Actions.createParticipantSuccess(data.metadata))
            notification.success({ message: "Register successfully!" })
        }, Func.errorHandle(Types.CREATE_PARTICIPANT_FAILURE))
    )
}

function* getEventParticipants() {
    yield takeLatest(
        Types.GET_EVENT_PARTICIPANTS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getEventParticipants, action?.params)

            if (!SUCCESS_CODE.includes(data?.status)) {
                throw data;
            }

            yield put(Actions.getEventParticipantsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_EVENT_PARTICIPANTS_FAILURE))
    )
}

function* deleteParticipant() {
    yield takeLatest(
        Types.DELETE_PARTICIPANT_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.deleteParticipant, action?.params)

            if (!SUCCESS_CODE.includes(data?.status)) {
                throw data;
            }

            yield put(Actions.deleteParticipantSuccess(action?.params))
            notification.success({ message: "Deleted participant" })
        }, Func.errorHandle(Types.DELETE_PARTICIPANT_FAILURE))
    )

}

function* getUserParticipant() {
    yield takeLatest(
        Types.GET_USER_PARTICIPANTS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getUserParticipant, action?.params)

            if (!SUCCESS_CODE.includes(data?.status)) {
                throw data;
            }

            yield put(Actions.getUserParticipantsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_USER_PARTICIPANTS_FAILURE))
    )
}

export function* participantSaga() {
    yield all([
        fork(createParticipant),
        fork(getEventParticipants),
        fork(deleteParticipant),
        fork(getUserParticipant)
    ])
}