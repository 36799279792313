import { pick } from "lodash";

export default class EventRequest {
    constructor(data = {}) {
        this.setData(data);
    }
    setData(data ={}){
        this.id = data?.id;
        this.name = data?.name;
        this.description = data?.description;
        this.event_time = data?.event_time;
        this.event_time_end = data?.event_time_end;
        this.event_location = data?.event_location;
        this.title = data?.title;
        this.organization_id = data?.organization_id;
        this.category_id = data?.category_id;
    }
    // exportCreate(){
    //     return pick(this, ["email", "username", "password", "role_id", "first_name", "last_name", "address"]);
    // }

    exportUpdate(){
        let pickKeys = [];
        for (let key in this) {
            if (this[key] !== undefined) {
                pickKeys.push(key);
            }
        }
        return pick(this, pickKeys);
    }
}