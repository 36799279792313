import ApiService from '../../utils/ApiService';
import Urls from '../../constants/Urls';

export function getEventCategories(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_EVENT_CATEGORIES,
        params: params,
        parser: (data) => data,
    }).get();
}