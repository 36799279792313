// Each parent route which has "path" will has their sub_menu's paths followed by this "path".
export const AUTH_ROUTE = {
    LOGIN: {
        title: "Login",
        sub_menu: [
            {
                title: "Login",
                path: "/login",
                group: "LOGIN",
            }
        ],
    },
    SIGNUP: {
        title: "Sign Up",
        sub_menu: [
            {
                title: "Sign Up",
                path: "/signup",
                group: "SIGNUP",
            }
        ],
    },
    FORGOT_PASSWORD: {
        title: "Forgot Password",
        sub_menu: [
            {
                title: "Forgot Password",
                path: "/forgot-password",
                group: "FORGOT_PASSWORD",
            }
        ],
    },

}

export const PUBLIC_ROUTE = {
    HOME: {
        title: "Home",
        sub_menu: [
            {
                title: "Home",
                path: "/",
                group: "HOME",
            },
        ],
    },
    EVENT: {
        title: "Event",
        sub_menu: [
            {
                title: "Event",
                path: "/event/:id",
                group: "EVENT",
            }
        ]
    },
    ORGANIZATION: {
        title: "Organization",
        sub_menu: [
            {
                title: "Organization",
                path: "/organization/:id",
                group: "ORGANIZATION",
            }
        ]
    },
    SEARCH_EVENTS: {
        title: "Search Evenst",
        sub_menu: [
            {
                title: "Search event",
                path: "/search",
                group: "SEARCH_EVENTS",
            },
        ],
    
    },
}

export const USER_ROUTE = {
    MY_PROFILE: {
        title: "Account setting",
        sub_menu: [
            {
                title: "My profile and account setting",
                path: "/manage/my-profile",
                group: "MY_PROFILE",

            }
        ]
    },
    REGISTERED_EVENTS: {
        title: "Registered Events",
        sub_menu: [
            {
                title: "Manage events that I registered",
                path: "/user/registered-events",
                group: "REGISTERED_EVENTS",

            }
        ]
    },
    CREATE_EVENT: {
        title: "Create Event",
        sub_menu: [
            {
                title: "Create your own event!",
                path: "/manage/create-event",
                group: "CREATE_EVENT",
            },
        ],
    },
    MANAGE_EVENT: {
        title: "Manage Event",
        sub_menu: [
            {
                title: "Manage my events",
                path: "/manage/manage-event",
                group: "MANAGE_EVENT",
                sub_menu: [
                    {
                        title: "Edit event",
                        path: "/manage/manage-event/:id",
                        group: "MANAGE_EVENT",
                    }
                ]
            },

        ],
    },
    CREATE_ORGANIZATION: {
        title: "Create Organization",
        sub_menu: [
            {
                title: "Create my organization",
                path: "/manage/create-organization",
                group: "CREATE_ORGANIZATION",
            },
        ],
    },
    MANAGE_ORGANIZATION: {
        title: "Manage Organization",
        sub_menu: [
            {
                title: "Manage my organizations",
                path: "/manage/manage-organization",
                group: "MANAGE_ORGANIZATION",
                sub_menu: [
                    {
                        title: "Edit organization",
                        path: "/manage/manage-organization/:id",
                        group: "MANAGE_ORGANIZATION",
                    }
                ]
            },

        ],
    },
}
