import { all } from "redux-saga/effects";
import { eventSaga } from "./eventSaga";
import { authSaga } from "./authSaga";
import { organizationSaga } from "./organizationSaga";
import { eventCategorySaga } from "./eventCategorySaga";
import { participantSaga } from "./participantSaga";
import { profileSaga } from "./profileSaga";

export default function* rootSaga() {
    yield all([
      eventSaga(),
      authSaga(),
      organizationSaga(),
      eventCategorySaga(),
      participantSaga(),
      profileSaga()
    ]);
  }
  