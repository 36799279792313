import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/EventType";
import * as Actions from "../actions/eventAction";
import * as Services from "../services/eventService";
import * as Func from "../../utils/functions"
import { SUCCESS_CODE } from "../../constants/define";
import { notification } from "antd";
import { USER_ROUTE } from "../../constants/routers";
import EventRequest from "../mapping/Request/EventRequest";

function* getEvents() {
    yield takeLatest(
        Types.GET_EVENTS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getEvents, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }
            yield put(Actions.getEventsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_EVENTS_FAILURE))
    )
}


function* createEvent(){
    yield takeLatest(
        Types.CREATE_EVENT_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.createEvent, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.createEventSuccess(data.metadata))
            yield call(Func.redirectTo, USER_ROUTE.MANAGE_EVENT.sub_menu[0].sub_menu[0].path.replace(":id", data.metadata.id));
        }, Func.errorHandle(Types.CREATE_EVENT_FAILURE))
    )

}

function* getUserEvents(){
    yield takeLatest(
        Types.GET_USER_EVENTS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getUserEvents, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.getUserEventsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_USER_EVENTS_FAILURE))
    )
}

function* getOrganizationEvents(){
    yield takeLatest(
        Types.GET_ORGANIZATION_EVENTS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getOrganizationEvents, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.getOrganizationEventsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_ORGANIZATION_EVENTS_FAILURE))
    )
}

function* deleteEvent(){
    yield takeLatest(
        Types.DELETE_EVENT_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.deleteEvent, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.deleteEventSuccess(action?.params))
            notification.success({ message: "Deleted event" });
        }, Func.errorHandle(Types.DELETE_EVENT_FAILURE))
    )

}

function* getEvent(){
    yield takeLatest(
        Types.GET_EVENT_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getEvent, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.getEventSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_EVENT_FAILURE))
    )

}

function* getSearchEvents(){
    yield takeLatest(
        Types.GET_SEARCH_EVENTS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getSearchEvents, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.getSearchEventsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_SEARCH_EVENTS_FAILURE))
    )

}

function* updateEvent(){
    yield takeLatest(
        Types.UPDATE_EVENT_REQUEST,
        Func.sagaWrapper(function* (action) {
            const sendData = new EventRequest(action?.params).exportUpdate();
            const data = yield call(Services.updateEvent, sendData)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.updateEventSuccess(data.metadata))
            notification.success({ message: "Updated event" });
        }, Func.errorHandle(Types.UPDATE_EVENT_FAILURE))
    )
}

export function* eventSaga() {
    yield all([
        fork(getEvents),
        fork(createEvent),
        fork(getUserEvents),
        fork(deleteEvent),
        fork(getEvent),
        fork(updateEvent),
        fork(getOrganizationEvents),
        fork(getSearchEvents),
    ])
}