import React from 'react';
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux';
import store from './data/store/index';
import MainCheck from './layouts/MainCheck';
import history from './utils/history';

ReactDOM.render(
 
  <Provider store={store}>
    <Router history={history}>
      
      <MainCheck/>

    </Router>
  </Provider>

  , document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
