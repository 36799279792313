export const CREATE_PARTICIPANT = "CREATE_PARTICIPANT";
export const CREATE_PARTICIPANT_REQUEST = "CREATE_PARTICIPANT_REQUEST";
export const CREATE_PARTICIPANT_SUCCESS = "CREATE_PARTICIPANT_SUCCESS";
export const CREATE_PARTICIPANT_FAILURE = "CREATE_PARTICIPANT_FAILURE";

export const GET_EVENT_PARTICIPANTS = "GET_EVENT_PARTICIPANTS";
export const GET_EVENT_PARTICIPANTS_REQUEST = "GET_EVENT_PARTICIPANTS_REQUEST";
export const GET_EVENT_PARTICIPANTS_SUCCESS = "GET_EVENT_PARTICIPANTS_SUCCESS";
export const GET_EVENT_PARTICIPANTS_FAILURE = "GET_EVENT_PARTICIPANTS_FAILURE";

export const DELETE_PARTICIPANT = "DELETE_PARTICIPANT";
export const DELETE_PARTICIPANT_REQUEST = "DELETE_PARTICIPANT_REQUEST";
export const DELETE_PARTICIPANT_SUCCESS = "DELETE_PARTICIPANT_SUCCESS";
export const DELETE_PARTICIPANT_FAILURE = "DELETE_PARTICIPANT_FAILURE";

export const GET_USER_PARTICIPANTS = "GET_USER_PARTICIPANTS";
export const GET_USER_PARTICIPANTS_REQUEST = "GET_USER_PARTICIPANTS_REQUEST";
export const GET_USER_PARTICIPANTS_SUCCESS = "GET_USER_PARTICIPANTS_SUCCESS";
export const GET_USER_PARTICIPANTS_FAILURE = "GET_USER_PARTICIPANTS_FAILURE";