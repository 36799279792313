import ApiService from "../../utils/ApiService";
import Urls from "../../constants/Urls";


export function signUp(data) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SIGNUP,
        parser: (data) => data
    }).post(data);
}

export function signIn(username, password) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.SIGNIN,
        parser: (data) => data
    }).post({ username, password });
}