import { Switch, Route, withRouter } from "react-router-dom";
import Layout from "./Main";
import LoginRoute from "./LoginRoute";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import { AUTH_ROUTE, PUBLIC_ROUTE, USER_ROUTE } from "../constants/routers";

const MainCheck = () => {
    return (
        <Route>
            <Switch>

                {/* Login Route */}
                <LoginRoute
                    path={AUTH_ROUTE.LOGIN.sub_menu[0].path}
                    component={Layout.Login}
                    group={AUTH_ROUTE.LOGIN.sub_menu[0].group}
                    exact
                />

                <LoginRoute
                    path={AUTH_ROUTE.SIGNUP.sub_menu[0].path}
                    component={Layout.Register}
                    group={AUTH_ROUTE.SIGNUP.sub_menu[0].group}
                    exact
                />





                {/* Public Route */}
                <PublicRoute
                    path={PUBLIC_ROUTE.HOME.sub_menu[0].path}
                    component={Layout.Home}
                    group={PUBLIC_ROUTE.HOME.sub_menu[0].group}
                    exact
                />

                <PublicRoute
                    path={PUBLIC_ROUTE.EVENT.sub_menu[0].path}
                    component={Layout.EventDetail}
                    group={PUBLIC_ROUTE.EVENT.sub_menu[0].group}
                    exact
                />
                <PublicRoute
                    path={PUBLIC_ROUTE.ORGANIZATION.sub_menu[0].path}
                    component={Layout.OrganizationDetail}
                    group={PUBLIC_ROUTE.ORGANIZATION.sub_menu[0].group}
                    exact
                />
                <PublicRoute
                    path={PUBLIC_ROUTE.SEARCH_EVENTS.sub_menu[0].path}
                    component={Layout.SearchEvents}
                    group={PUBLIC_ROUTE.SEARCH_EVENTS.sub_menu[0].group}
                    exact
                />


                {/* Private Route */}
                {/* Organization */}
                <PrivateRoute
                    path={USER_ROUTE.CREATE_ORGANIZATION.sub_menu[0].path}
                    component={Layout.CreateOrganization}
                    group={USER_ROUTE.CREATE_ORGANIZATION.sub_menu[0].group}
                    exact
                />

                <PrivateRoute
                    path={USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].path}
                    component={Layout.ManageOrganizations}
                    group={USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].group}
                    exact
                />

                <PrivateRoute
                    path={USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].sub_menu[0].path}
                    component={Layout.EditOrganization}
                    group={USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].sub_menu[0].group}
                    exact
                />

                {/* Event */}
                <PrivateRoute
                    path={USER_ROUTE.CREATE_EVENT.sub_menu[0].path}
                    component={Layout.CreateEvent}
                    group={USER_ROUTE.CREATE_EVENT.sub_menu[0].group}
                    exact
                />
                <PrivateRoute
                    path={USER_ROUTE.MANAGE_EVENT.sub_menu[0].path}
                    component={Layout.ManageEvent}
                    group={USER_ROUTE.MANAGE_EVENT.sub_menu[0].group}
                    exact
                />
                <PrivateRoute
                    path={USER_ROUTE.MANAGE_EVENT.sub_menu[0].sub_menu[0].path}
                    component={Layout.EditEvent}
                    group={USER_ROUTE.MANAGE_EVENT.sub_menu[0].sub_menu[0].group}
                    exact
                />

                {/* Paricipation */}
                <PrivateRoute
                    path={USER_ROUTE.REGISTERED_EVENTS.sub_menu[0].path}
                    component={Layout.RegisteredEvents}
                    group={USER_ROUTE.REGISTERED_EVENTS.sub_menu[0].group}
                    exact
                />

                {/* My Profile */}
                <PrivateRoute
                    path={USER_ROUTE.MY_PROFILE.sub_menu[0].path}
                    component={Layout.MyProfile}
                    group={USER_ROUTE.MY_PROFILE.sub_menu[0].group}
                    exact
                />






                {/* 404 Routes */}
                <PublicRoute
                    path=''
                    component={Layout.Error404}
                    exact={false}
                />

            </Switch>
        </Route>
    )

}

export default withRouter(MainCheck);