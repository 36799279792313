import { pick } from "lodash";

export default class OrganizationRequest {
    constructor(data = {}) {
        this.setData(data);
    }
    setData(data ={}){
        this.id = data?.id;
        this.account_id = data?.account_id;
        this.name = data?.name;
        this.url = data?.url;
    }
    // exportCreate(){
    //     return pick(this, ["email", "username", "password", "role_id", "first_name", "last_name", "address"]);
    // }

    exportUpdate(){
        let pickKeys = []
        for (let key in this) {
            if (this[key] !== undefined) {
                pickKeys.push(key);
            }
        }
        return pick(this, pickKeys);
    }
}