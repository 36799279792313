import { Layout, Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import SearchBar from "./SearchBar";
import { AUTH_ROUTE, PUBLIC_ROUTE, USER_ROUTE } from "../../constants/routers";
import { useLogout, useGetLogged, useGetMe } from "../../data/hooks/authHook";
import { ROLE } from "../../constants/define";
import history from "../../utils/history";
import { PlusOutlined, UnorderedListOutlined, UserOutlined, FormOutlined, LoginOutlined } from "@ant-design/icons";
const { Header: AntdHeader } = Layout;

//TODO: handle menu when user logged in, when user in private route and public route

const onSearch = (value) => {
  if (value == "") return;
  history.push(PUBLIC_ROUTE.SEARCH_EVENTS.sub_menu[0].path + "?name=" + value);
}

let items = [
  {
    key: PUBLIC_ROUTE.HOME.sub_menu[0].path,
    label: (
      <Link to={PUBLIC_ROUTE.HOME.sub_menu[0].path}>
        <img
          height='55'
          style={{
            // backgroundSize: "",
            objectFit: "cover",
            borderRadius: "20px",
          }}
          // src={'https://storage.googleapis.com/media-realestateiq/realestateiq/uploads/1668250958147-megalith_log.png'}
          alt='Event Logo'
        />
      </Link>
    )
  },
  {
    key: PUBLIC_ROUTE.SEARCH_EVENTS.sub_menu[0].path,
    label: (
      <SearchBar placeholder='Search events' onSearch={onSearch} style={{ width: "30vw", verticalAlign: 'middle' }} />
    )
  },

  {
    key: USER_ROUTE.CREATE_EVENT.sub_menu[0].path + "_outside",
    label: (
      <Link to={USER_ROUTE.CREATE_EVENT.sub_menu[0].path}>
        <PlusOutlined/>
        <span>Create Event</span>
      </Link>
    )

  },
  {
    key: AUTH_ROUTE.LOGIN.sub_menu[0].path,
    label: (
      <Link to={AUTH_ROUTE.LOGIN.sub_menu[0].path}>
        <LoginOutlined />
        <span>Log In</span>
      </Link>
    )
  },
  {
    key: AUTH_ROUTE.SIGNUP.sub_menu[0].path,
    label: (
      <Link to={AUTH_ROUTE.SIGNUP.sub_menu[0].path}>
        <FormOutlined />
        <span>Sign Up</span>
      </Link>
    )
  },
]

const userDropDown1 =
{
  type: "group",
  label: "User setting",
  children: [
    {
      key: USER_ROUTE.REGISTERED_EVENTS.sub_menu[0].path,
      label: (
        <Link to={USER_ROUTE.REGISTERED_EVENTS.sub_menu[0].path}>
          {USER_ROUTE.REGISTERED_EVENTS.title}
        </Link>
      )
    },
    {
      key: USER_ROUTE.MY_PROFILE.sub_menu[0].path,
      label: (
        <Link to={USER_ROUTE.MY_PROFILE.sub_menu[0].path}>
          {USER_ROUTE.MY_PROFILE.title}
        </Link>
      )
    }

  ]
}
const userDropDown2 = {
  type: "group",
  label: "Manage",
  children: [
    {
      key: USER_ROUTE.MANAGE_EVENT.sub_menu[0].path,
      label: (
        <Link to={USER_ROUTE.MANAGE_EVENT.sub_menu[0].path}>
          {USER_ROUTE.MANAGE_EVENT.title}
        </Link>
      )
    },
    {
      key: USER_ROUTE.CREATE_EVENT.sub_menu[0].path,
      label: (
        <Link to={USER_ROUTE.CREATE_EVENT.sub_menu[0].path}>
          {USER_ROUTE.CREATE_EVENT.title}
        </Link>
      )
    },
    {
      key: USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].path,
      label: (
        <Link to={USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].path}>
          {USER_ROUTE.MANAGE_ORGANIZATION.title}
        </Link>
      )
    },
    {
      key: USER_ROUTE.CREATE_ORGANIZATION.sub_menu[0].path,
      label: (
        <Link to={USER_ROUTE.CREATE_ORGANIZATION.sub_menu[0].path}>
          {USER_ROUTE.CREATE_ORGANIZATION.title}
        </Link>
      )
    }
  ]
}


const adminDropDown = {
  type: "group",
  label: "Manage (Admin)",
  children: [
    {
      //TODO: add admin manage, user information manage
    }
  ]
}

function Header() {

  const logout = useLogout();
  const isLogged = useGetLogged();
  const me = useGetMe();

  if (isLogged) {

    const logoutDropDown = {
      type: "group",
      label: "Logout",
      children: [
        {
          key: "logout",
          label: (
            <div onClick={logout}>
              Logout
            </div>
          )
        }
      ]
    }
    const menuDropDown = me?.role_id == ROLE.admin ? [userDropDown1, userDropDown2, adminDropDown, logoutDropDown] : [userDropDown1, userDropDown2, logoutDropDown];

    // replace login with manage event, sign up with user
    items = items.map(item => {
      if (item.key === AUTH_ROUTE.LOGIN.sub_menu[0].path) {
        return {
          key: USER_ROUTE.MANAGE_EVENT.sub_menu[0].path + "_outside",
          label: (
            <Link to={USER_ROUTE.MANAGE_EVENT.sub_menu[0].path}>
              <UnorderedListOutlined />
              <span>{USER_ROUTE.MANAGE_EVENT.title}</span>
            </Link>
          )
        }
      }

      if (item.key === AUTH_ROUTE.SIGNUP.sub_menu[0].path) {
        return {
          key: me?.username,
          label: (
            <div>
              <UserOutlined />
              <span>User</span>
            </div>
          ),
          children: menuDropDown
        }
      }
      return item;
    })
  }

  return (
    <div style={{ borderBottom: "1px solid #e6e8ed" }}>
      <AntdHeader
        style={{ backgroundColor: "white" }}
      >
        <Menu
          style={{ display: 'flex', justifyContent: "space-between", fontWeight: "bold", backgroundColor: "white" }}
          mode="horizontal"
          items={items}
        />
      </AntdHeader>
    </div>
  )
};

export default withRouter(Header);

