import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";
import eventReducer from "./eventReducer";
import authReducer from "./authReducer";
import organizationReducer from "./organizationReducer";
import eventCategoryReducer from "./eventCategoryReducer";
import participantReducer from "./participantReducer";
import profileReducer from "./profileReducer";

export default combineReducers({
    loadingReducer,
    eventReducer,
    authReducer,
    organizationReducer,
    eventCategoryReducer,
    participantReducer,
    profileReducer
})