import * as Types from "../types/ParticipantType";

// create
export const createParticipantRequest = (params) => ({
    type: Types.CREATE_PARTICIPANT_REQUEST,
    params
})

export const createParticipantSuccess = (data) => ({
    type: Types.CREATE_PARTICIPANT_SUCCESS,
    data
})

// get by event 
export const getEventParticipantsRequest = (params) => ({
    type: Types.GET_EVENT_PARTICIPANTS_REQUEST,
    params
})

export const getEventParticipantsSuccess = (data) => ({
    type: Types.GET_EVENT_PARTICIPANTS_SUCCESS,
    data
})

// delete
export const deleteParticipantRequest = (params) => ({
    type: Types.DELETE_PARTICIPANT_REQUEST,
    params
})

export const deleteParticipantSuccess = (data) => ({
    type: Types.DELETE_PARTICIPANT_SUCCESS,
    data
})

// GET USER participation
export const getUserParticipantsRequest = (params) => ({
    type: Types.GET_USER_PARTICIPANTS_REQUEST,
    params
})

export const getUserParticipantsSuccess = (data) => ({
    type: Types.GET_USER_PARTICIPANTS_SUCCESS,
    data
})