import * as Types from "../types/AuthType";
import { CONFIG_LOCAL_STORAGE } from "../../constants/define";

const initialState = {
    logged: !!localStorage.getItem(CONFIG_LOCAL_STORAGE.COMMON_ACCESS_TOKEN),
    user: {}
}

const authReducer = (state=initialState, action) => {
    switch(action.type){
        case Types.SIGNIN_SUCCESS:{
            return {
                ...state,
                logged: true,
                user: action.result
            }
        }

        case Types.SIGNOUT_SUCCESS:{
            return {
                ...state,
                logged: false,
                user: {}
            }
        }

        default:
            return state;
    }
}

export default authReducer;