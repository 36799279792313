import { Route, Redirect, withRouter } from "react-router-dom";
import { useGetLogged } from "../data/hooks/authHook";
import PublicContainer from "./PublicContainer";
import { PUBLIC_ROUTE } from "../constants/routers";

const LoginRoute = ({ component: Component, ...rest }) => {
    const isLogged = useGetLogged();

    return (
        <Route
            {...rest}
            render={(props) => {

                return isLogged ?
                    <Redirect to={{ pathname: PUBLIC_ROUTE.HOME.sub_menu[0].path, state: { from: rest?.location } }} /> :
                    <PublicContainer>
                        <Component {...props} />
                    </PublicContainer>

            }}
        >
        </Route>
    )
}

export default withRouter(LoginRoute);