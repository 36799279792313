import {
    call,
    put,
    takeLatest,
    fork,
    all,
} from "redux-saga/effects";

import AuthRequest from "../mapping/Request/AuthRequest";
import * as Types from "../types/AuthType";
import * as Func from "../../utils/functions";
import * as Actions from "../actions/authAction";
import * as Services from "../services/authService";
import { CONFIG_LOCAL_STORAGE } from "../../constants/define";
import { AUTH_ROUTE, PUBLIC_ROUTE } from "../../constants/routers";
import { SUCCESS_CODE } from "../../constants/define";
import history from "../../utils/history";

function* signInSaga() {
    yield takeLatest(
        Types.SIGNIN_REQUEST,
        Func.sagaWrapper(function* (action) {
            const body = action?.params;
            const data = yield call(Services.signIn, body.username, body.password);

            if (!SUCCESS_CODE.includes(data?.status)) {
                throw data;
            }
            yield put(Actions.signInSuccess(data.metadata));
            localStorage.setItem(CONFIG_LOCAL_STORAGE.COMMON_ACCESS_TOKEN, data?.metadata?.token);
            localStorage.setItem(CONFIG_LOCAL_STORAGE.ACCOUNT_ID, data?.metadata?.id);
            localStorage.setItem(CONFIG_LOCAL_STORAGE.ROLE_ID, data?.metadata?.role_id);
            if(history.location.state?.from)
                yield call(Func.redirectTo, history.location.state?.from);
            else
                yield call(Func.redirectTo, AUTH_ROUTE.LOGIN.sub_menu[0].path);

        }, Func.errorHandle(Types.SIGNIN_FAILURE))
    )
}

function* signOutSaga() {
    yield takeLatest(
        Types.SIGNOUT_REQUEST,
        Func.sagaWrapper(function* (action) {
            localStorage.removeItem(CONFIG_LOCAL_STORAGE.COMMON_ACCESS_TOKEN);
            localStorage.removeItem(CONFIG_LOCAL_STORAGE.ACCOUNT_ID);
            localStorage.removeItem(CONFIG_LOCAL_STORAGE.ROLE_ID);
            yield put(Actions.signOutSuccess());
            yield call(Func.redirectTo, PUBLIC_ROUTE.HOME.sub_menu[0].path); //TODO: fix redirect to home page (current redirect to Login)
        }, Func.errorHandle(Types.SIGNOUT_FAILURE))
    )
}

function* signUpSaga() {
    yield takeLatest(
        Types.SIGNUP_REQUEST,
        Func.sagaWrapper(function* (action) {
            const inputData = new AuthRequest(action?.params).exportCreate();
            const data = yield call(Services.signUp, inputData);

            if (!SUCCESS_CODE.includes(data?.status)) {
                throw data;
            }

            yield put(Actions.signUpSuccess(data.metadata));
            yield call(Func.redirectTo, AUTH_ROUTE.LOGIN.sub_menu[0].path);
        }, Func.errorHandle(Types.SIGNUP_FAILURE))
    )
}

export function* authSaga() {
    yield all([
        fork(signInSaga),
        fork(signOutSaga),
        fork(signUpSaga),
    ])
}