import React, { Component, Fragment, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { Layout, Spin } from "antd";
import { Content } from "antd/lib/layout/layout";
import Header from "./common/Header";
//import routers from "../constants/routers";
//TODO: add footer

class PublicContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>

                <Header />
                <Content style={{padding: "24px 48px", backgroundColor: "white", minHeight: "90vh"}}>
                    <Suspense fallback={
                        <div className='spinning' style={{display: "flex", alignItems: "center", height: "80vh"}}>
                            <Spin tip='Loading...' />
                        </div>
                    }>
                        {React.createElement(
                            this.props.children.type,
                            {
                                ...this.props.children.props,
                            },
                            this.props.children
                        )}
                    </Suspense>
                </Content>

            </>
        )

    }
}

//TODO: handle loading signout
const loadingSelector = (state) => state.loading;

export default withRouter(PublicContainer);