import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/EventCategoryType";
import * as Actions from "../actions/eventCategoryAction";
import * as Services from "../services/eventCategoryService";
import * as Func from "../../utils/functions"
import { SUCCESS_CODE } from "../../constants/define";
import { notification } from "antd";

function* getEventCategories() {
    yield takeLatest(
        Types.GET_EVENT_CATEGORIES_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getEventCategories, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }
            yield put(Actions.getEventCategoriesSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_EVENT_CATEGORIES_FAILURE))
    )
}

export function* eventCategorySaga() {
    yield all([
        fork(getEventCategories),
    ])
}