import * as Types from  "../types/EventCategoryType"

// get Event Categories
export const getEventCategoriesRequest = (params) => ({
    type: Types.GET_EVENT_CATEGORIES_REQUEST,
    params
});

export const getEventCategoriesSuccess = (data) => ({
    type: Types.GET_EVENT_CATEGORIES_SUCCESS,
    data
});