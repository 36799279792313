import * as Types from "../types/EventType";
import { standardizeParams } from "../../utils/functions";

// get Events
export const getEventsRequest = (params) => ({
    type: Types.GET_EVENTS_REQUEST,
    params
});
export const getEventsSuccess = (data) => ({
    type: Types.GET_EVENTS_SUCCESS,
    data
});

// create Event
export const createEventRequest = (params) => ({
    type: Types.CREATE_EVENT_REQUEST,
    params
});
export const createEventSuccess = (data) => ({
    type: Types.CREATE_EVENT_SUCCESS,
    data
});

// delete Event
export const deleteEventRequest = (params) => ({
    type: Types.DELETE_EVENT_REQUEST,
    params
});

export const deleteEventSuccess = (data) => ({
    type: Types.DELETE_EVENT_SUCCESS,
    data
});

// get User Events
export const getUserEventsRequest = (params) => ({
    type: Types.GET_USER_EVENTS_REQUEST,
    params
});
export const getUserEventsSuccess = (data) => ({
    type: Types.GET_USER_EVENTS_SUCCESS,
    data
});

// get Organization Events
export const getOrganizationEventsRequest = (params) => ({
    type: Types.GET_ORGANIZATION_EVENTS_REQUEST,
    params
});
export const getOrganizationEventsSuccess = (data) => ({
    type: Types.GET_ORGANIZATION_EVENTS_SUCCESS,
    data
});

// get Event
export const getEventRequest = (params) => ({
    type: Types.GET_EVENT_REQUEST,
    params
});
export const getEventSuccess = (data) => ({
    type: Types.GET_EVENT_SUCCESS,
    data
});

// update Event
export const updateEventRequest = (params) => ({
    type: Types.UPDATE_EVENT_REQUEST,
    params
});
export const updateEventSuccess = (data) => ({
    type: Types.UPDATE_EVENT_SUCCESS,
    data
});

// get search event
export const getSearchEventsRequest = (params) => ({
    type: Types.GET_SEARCH_EVENTS_REQUEST,
    params
});

export const getSearchEventsSuccess = (data) => ({
    type: Types.GET_SEARCH_EVENTS_SUCCESS,
    data
});