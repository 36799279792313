import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as HookAction from "../actions/authAction";
import { selectMe, selectLogged } from "../reselects/authSelector";

//TODO: fix useGetMe
export const useGetMe = () => {
    const me = useSelector(selectMe());
    return me;
}

export const useGetLogged = () => {
    const logged = useSelector(selectLogged());
    return logged;
}

export const useLogin = () => {
    const dispatch = useDispatch();
    return useCallback(
        (filters) => dispatch(HookAction.signInRequest(filters)), [dispatch]);

}

export const useLogout = () => {
    const dispatch = useDispatch();
    return useCallback(
        () => dispatch(HookAction.signOutRequest()), [dispatch]);
}

export const useSignUp = () => {
    const dispatch = useDispatch();
    return useCallback(
        (filters) => dispatch(HookAction.signUpRequest(filters)), [dispatch]);
}