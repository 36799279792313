import ApiService from "../../utils/ApiService";
import Urls from "../../constants/Urls";

export function createOrganization(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CREATE_ORGANIZATION,
        parser: (data) => data,
    }).post(params);

}

export function getUserOrganizations(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_USER_ORGANIZATIONS,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).get();
}

export function getOrganization(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_ORGANIZATION,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).get();
}

export function updateOrganization(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_ORGANIZATION,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).put(params);
}

export function deleteOrganization(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DELETE_ORGANIZATION,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).delete();
}