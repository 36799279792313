import ApiService from "../../utils/ApiService";
import Urls from "../../constants/Urls";

export function createParticipant(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.CREATE_PARTICIPANT,
    parser: (data) => data,
  }).post(params);
}

export function getEventParticipants(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_EVENT_PARTICIPANTS,
    endpointParams: { id: params?.id },
    parser: (data) => data,
  }).get();
}

export function deleteParticipant(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.DELETE_PARTICIPANT,
    endpointParams: { id: params?.id },
    parser: (data) => data,
  }).delete(params);
}

export function getUserParticipant(params) {
  return new ApiService({
    baseURL: process.env.REACT_APP_API_URL,
    endpoint: Urls.GET_USER_PARTICIPANTS,
    endpointParams: { id: params?.id },
    parser: (data) => data,
  }).get();
}