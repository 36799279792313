import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Header from "./common/Header";
import { Layout, Spin } from 'antd';
import PrivateSider from "./common/PrivateSider";
import history from "../utils/history";
import { USER_ROUTE } from "../constants/routers";
const { Content } = Layout;


class PrivateContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let header = ""
        Object.values(USER_ROUTE).forEach((route) => {
            if (history.location.pathname.includes(route.sub_menu[0].path)) {
                header = route.title;
            }
        })

        if(header == ""){
            //TODO: loop over ADMIN_ROUTE
        }

        return (
            <>
                <Header />
                <Layout>
                    <PrivateSider group={this.props?.group}/>
                    <Content style={{ padding: "36px 36px", minHeight: "90vh", backgroundColor: "#f4f4f9" }}>
                        <Suspense fallback={
                            <div className='spinning' style={{ display: "flex", alignItems: "center", height: "80vh" }}>
                                <Spin tip='Loading...' />
                            </div>
                        }>
                            {/* header for private routes */}
                            <h3 style={{fontWeight: "bolder", marginBottom: "0.5vw"}}>{header}</h3> 
                            {React.createElement(
                                this.props.children.type,
                                {
                                    ...this.props.children.props,
                                    path: this.props?.path,
                                },
                                this.props.children
                            )}
                        </Suspense>
                    </Content>
                </Layout>

            </>
        )
    }
}



export default withRouter(PrivateContainer);