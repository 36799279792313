import * as Types from "../types/OrganizationType";

// create organization
export const createOrganizationRequest = (params) => ({
    type: Types.CREATE_ORGANIZATION_REQUEST,
    params
})

export const createOrganizationSuccess = (data) => ({
    type: Types.CREATE_ORGANIZATION_SUCCESS,
    data
})

export const createOrganizationFailure = (data) => ({
    type: Types.CREATE_ORGANIZATION_FAILURE,
    data
})

// get organization
export const getOrganizationRequest = (params) => ({
    type: Types.GET_ORGANIZATION_REQUEST,
    params
})
export const getOrganizationSuccess = (data) => ({
    type: Types.GET_ORGANIZATION_SUCCESS,
    data
})
export const getOrganizationFailure = (data) => ({
    type: Types.GET_ORGANIZATION_FAILURE,
    data
})

// update organization
export const updateOrganizationRequest = (params) => ({
    type: Types.UPDATE_ORGANIZATION_REQUEST,
    params
})
export const updateOrganizationSuccess = (data) => ({
    type: Types.UPDATE_ORGANIZATION_SUCCESS,
    data
})
export const updateOrganizationFailure = (data) => ({
    type: Types.UPDATE_ORGANIZATION_FAILURE,
    data
})


// get user's organizations
export const getUserOrganizationsRequest = (params) => ({
    type: Types.GET_USER_ORGANIZATIONS_REQUEST,
    params
})

export const getUserOrganizationsSuccess = (data) => ({
    type: Types.GET_USER_ORGANIZATIONS_SUCCESS,
    data
})

export const getUserOrganizationsFailure = (data) => ({
    type: Types.GET_USER_ORGANIZATIONS_FAILURE,
    data
})

// delete organization

export const deleteOrganizationRequest = (params) => ({
    type: Types.DELETE_ORGANIZATION_REQUEST,
    params
})

export const deleteOrganizationSuccess = (data) => ({
    type: Types.DELETE_ORGANIZATION_SUCCESS,
    data
})

export const deleteOrganizationFailure = (data) => ({
    type: Types.DELETE_ORGANIZATION_FAILURE,
    data
})