import * as Types from '../types/OrganizationType';

const initialState = {
    organizations: [],
    organization: {},
}

const organizationReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CREATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organizations: [...state.organizations, action.data],
            }
        case Types.GET_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.data,
            }
        case Types.UPDATE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organization: action.data,
                organizations: state.organizations.map(org => org.id === action?.data?.id ? action.data : org),
            }
        case Types.GET_USER_ORGANIZATIONS_SUCCESS:
            return {
                ...state,
                organizations: action.data,
            }
        case Types.DELETE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                organizations: state.organizations.filter(org => org.id !== action.data?.id),
            }
        default:
            return state;
    }
}

export default organizationReducer;