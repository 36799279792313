import { pick } from "lodash";

export default class AuthRequest {
    constructor(data = {}) {
        this.setData(data);
    }
    setData(data ={}){
        this.id = data?.id || undefined;
        this.email = data?.email || undefined;
        this.username = data?.username || undefined;
        this.password = data?.password || undefined;
        this.role_id = data?.role_id || undefined;
        this.avatar = data?.avatar || undefined;
        this.first_name = data?.first_name || undefined;
        this.last_name = data?.last_name || undefined;
        this.address = data?.address || undefined;
        this.token = data?.token || undefined;
        this.refresh_token = data?.refresh_token || undefined; 
    }
    exportUpdate(){
        let pickKeys = [];
        for (let key in this) {
            if (this[key] !== undefined) {
                pickKeys.push(key);
            }
        }
        return pick(this, pickKeys);
    }
}