import { Route, withRouter } from "react-router-dom";
import PublicContainer from "./PublicContainer";
const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            return (
                <PublicContainer>
                    <Component {...props} />
                </PublicContainer>
            );
        }}
    >

    </Route>
)

export default withRouter(PublicRoute);