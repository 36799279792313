import * as Types from "../types/ProfileType"

const initialState = {
    profile: {},
    profiles: [],
}

const profileReducer = (state=initialState, action) => {
    switch(action.type){
        case Types.GET_MY_PROFILE_SUCCESS:{
            return {
                ...state,
                profile: action.data
            }
        }

        case Types.UPDATE_MY_PROFILE_SUCCESS: {
            return {
                ...state,
                profile: action.data
            }
        }

        case Types.UPDATE_MY_PASSWORD_SUCCESS: {
            return {
                ...state,
            }
        }

        default:
            return state;
    }
}

export default profileReducer;