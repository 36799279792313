import * as Types from "../types/AuthType";

export const signInRequest = (params) => ({
    type: Types.SIGNIN_REQUEST,
    params
})

export const signInSuccess = (data) => ({
    type: Types.SIGNIN_SUCCESS,
    data
})

export const signOutRequest = (params) => ({
    type: Types.SIGNOUT_REQUEST,
    params
})

export const signOutSuccess = (data) => ({
    type: Types.SIGNOUT_SUCCESS,
    data
})

export const signUpRequest = (params) => ({
    type: Types.SIGNUP_REQUEST,
    params
})

export const signUpSuccess = (data) => ({
    type: Types.SIGNUP_SUCCESS,
    data
})