import axios from "axios";
import {
  CONFIG_LOCAL_STORAGE,
} from "../constants/define";
import { getPathWithParams } from "./functions";
import * as LocalStorage from "./localStorage";
import ApiResponse from "../data/mapping/ApiResponse";
import Methods from "../constants/Methods";
import Urls from "../constants/Urls";
import queryString from "query-string";

export default class ApiService {
  /**
   * Create instance service for every API service.
   * @param {Object} options - The options to create.
   * @param {string} options.baseURL - setting the host of the request, required.
   * @param {Object} [options.params] - url params of the request instance (ex: {pageIndex: 1, pageSize: 10}).
   * @param {string} options.endpoint - endpoint  to call the request to (ex: 'api/admin/login').
   * @param {string} options.customMessageErrors
   * @param {Object} [options.headers] - headers of the request instance (ex: {'Content-Type' : 'application/json'}).
   * @param {Object} [options.endpointParams] - headers of the request instance (ex: {id: 'some-identity-id-string'}).
   * @param {function} [options.parser] - function to parse response body to whatever you what (ex: function(body) { return JSON.parse(body) } ).
   * @param {function} [options.dummy] - function to return a dumy data when api service is not ready yet (ex: function() { return 'some-fake-body-data' } ).
   * @param {boolean} [options.isDownload=false] - setting the request is a downloading request or not, default "false".
   * @param {boolean} [options.withoutAuth=false] - setting the request to skip the "Authorization" header, default "false".
   */
  constructor(options = {}) {
    this.initServiceInstance(options);
    this.endpointParams = options.endpointParams || {};
    this.customMessageErrors = options.customMessageErrors || "";
    this.endpoint = getPathWithParams(
      options.endpoint || undefined,
      this.endpointParams
    );
    this.params = options.params || {};
    this.headers = this.getRequestHeaders(options.headers || {});
    this.parser = options.parser || undefined;
    this.responseType = options.isDownload && "blob";
    this.dummy = options.dummy || undefined;
    this.onUploadProgress = options?.onUploadProgress || {};
  }

  initServiceInstance(options = {}) {
    if (!options.baseURL) {
      throw new Error('"baseUrl" is a required option for "ApiService" model');
    }
    const accessToken = options?.customToken
      ? options?.customToken
      : LocalStorage.get(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
    this.instance = axios.create({
      baseURL: options.baseURL,
      paramsSerializer: this.getRequestParams,
      timeout: 30000,
    });
    if (!options.withoutAuth && accessToken) {
      this.instance.defaults.headers.common["Authorization"] = {
        toString() {
          return `Bearer ${accessToken}`;
        },
      };
    }
  }

  getRequestData(data) {
    if (data && typeof data.export === "function") {
      return data.export();
    }
    return data;
  }

  getRequestParams(params = {}) {
    return queryString.stringify(params, { arrayFormat: "comma" });
  }

  getRequestHeaders(headers = {}) {
    const result = {};
    Object.keys(headers).forEach((header) => {
      let key = header.toLowerCase();
      result[key] = headers[header];
    });
    // result['accept-language'] = FEATURE_FLAGS.TRANSLATE_ENABLE ? (result['accept-language'] || LocalStorage.get(CONFIG_LOCAL_STORAGE.CONTENT_LANGUAGE)) : undefined;
    result["accept-language"] = "vi-VN";
    return result;
  }

  request(method, reqData) {
    const data = this.getRequestData(reqData);
    return new Promise((resolve, reject) => {
      if (this.dummy) {
        const result = this.dummy();
        resolve(result);
        return result;
      } else {
        const config = {
          params: this.params,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            ...this.headers,
            ...this.onUploadProgress,
          },
          data:
            method === Methods.POST || method === Methods.PUT
              ? undefined
              : data,
          responseType: this.responseType,
        };
        const secondParam =
          method === Methods.POST || method === Methods.PUT ? data : config;
        const thirdParam =
          method === Methods.POST || method === Methods.PUT
            ? config
            : undefined;
        const isAuthRequest =
          this.endpoint === Urls.LOGIN || this.endpoint === Urls.REFRESH;
        this.instance[method](this.endpoint, secondParam, thirdParam)
          .then((response) => {
            if (
              response &&
              response.data &&
              response.data.errors &&
              response.data.errors.length
            ) {
              const message =
                typeof response.data.errors[0] === "string"
                  ? response.data.errors[0]
                  : response.data.errors[0].message || response.message;
              const errorResp = new ApiResponse({
                ...response,
                message,
                errors: response.data.errors,
                request: reqData,
                success: true,
                customMessageErrors: this.customMessageErrors,
              });
              reject(errorResp);
              return errorResp;
            } else if (
              response.data.validators &&
              response.data.validators.length
            ) {
              const message = response.data.validators[0].message;
              const errorResp = new ApiResponse({
                ...response,
                message,
                errors: response.data.errors,
                request: reqData,
                success: false,
                customMessageErrors: this.customMessageErrors,
              });
              reject(errorResp);
              return errorResp;
            } else if (response.data.status === "FAIL") {
              const message = response.data.errors[0].message;
              const errorResp = new ApiResponse({
                ...response,
                message,
                errors: response.data.errors,
                request: reqData,
                success: false,
                customMessageErrors: this.customMessageErrors,
              });
              reject(errorResp);
              return errorResp;
            }
            const result = this.parser
              ? this.parser(response.data)
              : new ApiResponse({
                  ...response,
                  request: reqData,
                  success: true,
                  customMessageErrors: this.customMessageErrors,
                });
            resolve(result);
            return result;
          })
          .catch((err) => {
            if (err.response && err.response.status === 401 && !isAuthRequest) {
              LocalStorage.remove(CONFIG_LOCAL_STORAGE.ACCESS_TOKEN);
              //window.location.replace(PATHS.AUTHENTICATION_NOT_FOUND.path);
            } else if (
              err.response &&
              err.response.status === 403 &&
              !isAuthRequest
            ) {
              // history.push(PATHS.PAGE_403.path)
              // history.go();
            } else {
              const errorRes = new ApiResponse({
                ...err.response,
                ...(err.response || {}).data,
                request: reqData,
                success: false,
                customMessageErrors: this.customMessageErrors,
              });
              reject(errorRes);
              return errorRes;
            }
          });
      }
    });
  }

  /**
   * Make a GET request to the api service.
   * @param {any} body - setting the body of the request.
   */
  get = (body) => this.request(Methods.GET, body);

  /**
   * Make a POST request to the api service.
   * @param {any} data - setting the body data of the request.
   */
  post = (data) => this.request(Methods.POST, data);

  /**
   * Make a POST request to the api service.
   * @param {any} data - setting the body data of the request.
   */
  put = (data) => this.request(Methods.PUT, data);

  delete = (body) => this.request(Methods.DELETE, body);
}
