import * as Types from '../types/ParticipantType';

const initialState = {
    participants: [],
    participant: {}
};

const participantReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CREATE_PARTICIPANT_SUCCESS:
            console.log(action.data)
            return {
                ...state,
                participants: [...state.participants, action?.data[0]]
            }
        case Types.GET_EVENT_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                participants: action.data
            }
        case Types.DELETE_PARTICIPANT_SUCCESS:
            return {
                ...state,
                participants: state.participants.filter(participant => participant.id !== action.data.id)
            }
        case Types.GET_USER_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                participants: action.data
            }
        default:
            return state;
    }
}

export default participantReducer;