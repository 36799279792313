import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/OrganizationType";
import * as Actions from "../actions/organizationAction";
import * as Services from "../services/organizationService";
import * as Func from "../../utils/functions"
import { SUCCESS_CODE } from "../../constants/define";
import { notification } from "antd";
import { USER_ROUTE } from "../../constants/routers";
import OrganizationRequest from "../mapping/Request/OrganizationRequest";

function* createOrganization() {
    yield takeLatest(
        Types.CREATE_ORGANIZATION_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.createOrganization, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.createOrganizationSuccess(data.metadata))
            
            yield call(Func.redirectTo, USER_ROUTE.MANAGE_ORGANIZATION.sub_menu[0].sub_menu[0].path.replace(":id", data.metadata.id));
        }, Func.errorHandle(Types.CREATE_ORGANIZATION_FAILURE))
    )

}

function* getUserOrganizations() {
    yield takeLatest(
        Types.GET_USER_ORGANIZATIONS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getUserOrganizations, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            
            }

            yield put(Actions.getUserOrganizationsSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_USER_ORGANIZATIONS_FAILURE))
    )
}

function* updateOrganization() {
    yield takeLatest(
        Types.UPDATE_ORGANIZATION_REQUEST,
        Func.sagaWrapper(function* (action) {
            let sendData = new OrganizationRequest(action?.params).exportUpdate();
            const data = yield call(Services.updateOrganization, sendData)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.updateOrganizationSuccess(data.metadata))
            notification.success({ message: "Updated organization" });
        }, Func.errorHandle(Types.UPDATE_ORGANIZATION_FAILURE))
    )


}

function* getOrganization() {
    yield takeLatest(
        Types.GET_ORGANIZATION_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getOrganization, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.getOrganizationSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_ORGANIZATION_FAILURE))
    )

}

function* deleteOrganization(){
    yield takeLatest(
        Types.DELETE_ORGANIZATION_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.deleteOrganization, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.deleteOrganizationSuccess(action?.params))
            notification.success({ message: "Deleted organization" });
        }, Func.errorHandle(Types.DELETE_ORGANIZATION_FAILURE))
    )
}

export function* organizationSaga() {
    yield all([
        fork(createOrganization),
        fork(getUserOrganizations),
        fork(deleteOrganization),
        fork(getOrganization),
        fork(updateOrganization),

    ])
}