import { lazy } from "react";

// auth
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import ("./pages/Register"));

// user
const CreateOrganization = lazy(() => import ("./pages/Organization/CreateOrganization"));
const ManageOrganizations = lazy(() => import ("./pages/Organization/ManageOrganizations"));
const EditOrganization = lazy(() => import ("./pages/Organization/EditOrganization"));
const CreateEvent = lazy(() => import ("./pages/Event/CreateEvent"));
const ManageEvent = lazy(() => import ("./pages/Event/ManageEvent"));
const EditEvent = lazy(() => import ("./pages/Event/EditEvent"));
const RegisteredEvents = lazy(() => import ("./pages/RegisteredEvents"));
const MyProfile = lazy(() => import ("./pages/MyProfile"));

// admin

// public
const OrganizationDetail = lazy(() => import ("./pages/Organization/OrganizationDetail"));
const EventDetail = lazy(() => import ("./pages/Event/EventDetail"));
const Home = lazy(() => import("./pages/Home"));
const SearchEvents = lazy(() => import("./pages/SearchEvents"));

// errors
const Error404 = lazy(() => import("./pages/404"));
const Error403 = lazy(() => import("./pages/403"));



const Pages = {
    Home,
    Error404, 
    Error403,
    Login,
    Register,
    CreateOrganization,
    ManageOrganizations,
    EditOrganization,
    CreateEvent,
    ManageEvent,
    EventDetail,
    EditEvent,
    OrganizationDetail,
    RegisteredEvents, 
    MyProfile,
    SearchEvents
}

export default Pages;