import { Layout, Menu } from "antd";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import { useGetMe } from "../../data/hooks/authHook";
import { USER_ROUTE } from "../../constants/routers"; //TODO: add admin route
import { ROLE } from "../../constants/define";
import { SettingOutlined, UserOutlined } from "@ant-design/icons";
const { Sider } = Layout

let itemsUserBase = Object.values(USER_ROUTE).map((route) => {
    if(route.title == "Account setting") return;
    return {
        key: route.sub_menu[0].group,
        label: <Link to={route.sub_menu[0].path}>
            {route.title}
        </Link>
    };
});

let itemsUser = [
    {
        key: "account",
        label: <Link to={USER_ROUTE.MY_PROFILE.sub_menu[0].path}>
            {USER_ROUTE.MY_PROFILE.title}
        </Link>,
        icon: <SettingOutlined />
    },
    {
        key: "user",
        label: "User action",
        icon: <UserOutlined />,
        children: itemsUserBase
    }
]

// TODO: add admin items

let itemsAdmin = []


const PrivateSider = ({group}) => {
    const me = useGetMe();

    return (

        <Sider
            style={{borderRight: "1px solid #e6e8ed"}}
            width={220} >
            <Menu
                mode="inline"
                defaultSelectedKeys={[group]}
                defaultOpenKeys={['user','admin']}
                style={{
                    height: '100%',
                    borderRight: 0,
                }}
                items={me.role_id == ROLE.admin ? itemsAdmin : itemsUser}    
            />
        </Sider>
    )
}

export default withRouter(PrivateSider)