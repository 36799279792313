import ApiService from "../../utils/ApiService";
import Urls from "../../constants/Urls";

export function getMyProfile() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_MY_PROFILE,
        parser: (data) => data,
    }).get();
}

export function updateMyProfile(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_MY_PROFILE,
        parser: (data) => data,
    }).put(params);
}

export function updateMyPassword(params){
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_MY_PASSWORD,
        parser: (data) => data,
    }).put(params);
}