import ApiService from '../../utils/ApiService'
import Urls from '../../constants/Urls'

export function getEvents(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_EVENTS,
        params: params,
        parser: (data) => data,
    }).get();
}

export function createEvent(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CREATE_EVENT,
        parser: (data) => data,
    }).post(params);
}

export function getUserEvents(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_USER_EVENTS,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).get();
}

export function getOrganizationEvents(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_ORGANIZATION_EVENTS,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).get();
}

export function deleteEvent(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DELETE_EVENT,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).delete(params);
}

export function getEvent(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_EVENT,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).get();
}

export function getSearchEvents(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_SEARCH_EVENTS,
        params: params,
        parser: (data) => data,
    }).get();

}

export function updateEvent(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_EVENT,
        endpointParams: {id: params?.id},
        parser: (data) => data,
    }).put(params);
}