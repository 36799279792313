import * as Types from "../types/ProfileType"

// get
export const getMyProfileRequest = (params) => ({
    type: Types.GET_MY_PROFILE_REQUEST,
    params
})

export const getMyProfileSuccess = (data) => ({
    type: Types.GET_MY_PROFILE_SUCCESS,
    data
}) 

//update
export const updateMyProfileRequest = (params) => ({
    type: Types.UPDATE_MY_PROFILE_REQUEST,
    params
})

export const updateMyProfileSuccess = (data) => ({
    type: Types.UPDATE_MY_PROFILE_SUCCESS,
    data
})

// update pws
export const updateMyPasswordRequest = (params) => ({
    type: Types.UPDATE_MY_PASSWORD_REQUEST,
    params
})

export const updateMyPasswordSuccess = (data) => ({
    type: Types.UPDATE_MY_PASSWORD_SUCCESS,
    data
})