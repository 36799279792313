export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_REQUEST = "GET_EVENTS_REQUEST";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const GET_EVENT = "GET_EVENT";
export const GET_EVENT_REQUEST = "GET_EVENT_REQUEST";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";

export const CREATE_EVENT = "CREATE_EVENT";
export const CREATE_EVENT_REQUEST = "CREATE_EVENT_REQUEST";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILURE = "CREATE_EVENT_FAILURE";

export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_EVENT_REQUEST = "UPDATE_EVENT_REQUEST";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILURE = "UPDATE_EVENT_FAILURE";

export const DELETE_EVENT = "DELETE_EVENT";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";

export const GET_USER_EVENTS = 'GET_USER_EVENTS';
export const GET_USER_EVENTS_REQUEST = 'GET_USER_EVENTS_REQUEST';
export const GET_USER_EVENTS_SUCCESS = 'GET_USER_EVENTS_SUCCESS';
export const GET_USER_EVENTS_FAILURE = 'GET_USER_EVENTS_FAILURE';

export const GET_ORGANIZATION_EVENTS = 'GET_ORGANIZATION_EVENTS';
export const GET_ORGANIZATION_EVENTS_REQUEST = 'GET_ORGANIZATION_EVENTS_REQUEST';
export const GET_ORGANIZATION_EVENTS_SUCCESS = 'GET_ORGANIZATION_EVENTS_SUCCESS';
export const GET_ORGANIZATION_EVENTS_FAILURE = 'GET_ORGANIZATION_EVENTS_FAILURE';

export const GET_SEARCH_EVENTS = 'GET_SEARCH_EVENTS';
export const GET_SEARCH_EVENTS_REQUEST = 'GET_SEARCH_EVENTS_REQUEST';
export const GET_SEARCH_EVENTS_SUCCESS = 'GET_SEARCH_EVENTS_SUCCESS';
export const GET_SEARCH_EVENTS_FAILURE = 'GET_SEARCH_EVENTS_FAILURE';