// Backend's API URL

import { UPDATE_MY_PROFILE } from "../data/types/ProfileType";

const Urls = {

    // Auth
    SIGNIN: "/api/v1/sign-in",
    SIGNOUT: "/api/v1/sign-out",
    SIGNUP: "/api/v1/sign-up",

    // Organization
    CREATE_ORGANIZATION: "/api/v1/organizations/create",
    GET_USER_ORGANIZATIONS: "/api/v1/organizations/account/:id",
    DELETE_ORGANIZATION: "/api/v1/organizations/:id",
    GET_ORGANIZATION: "/api/v1/organizations/:id",
    UPDATE_ORGANIZATION: "/api/v1/organizations/:id",


    // Events
    GET_EVENTS: "/api/v1/events",
    CREATE_EVENT: "/api/v1/events/create",
    GET_USER_EVENTS: "/api/v1/events/account/:id",
    GET_ORGANIZATION_EVENTS: "/api/v1/events/organization/:id",
    DELETE_EVENT: "/api/v1/events/:id",
    GET_EVENT: "/api/v1/events/:id",
    UPDATE_EVENT: "/api/v1/events/:id",
    GET_SEARCH_EVENTS: "/api/v1/events/search",

    // Event Categories
    GET_EVENT_CATEGORIES: "/api/v1/categories",

    // Participants
    CREATE_PARTICIPANT: "/api/v1/participates/create",
    GET_EVENT_PARTICIPANTS: "/api/v1/participates/event/:id",
    DELETE_PARTICIPANT: "/api/v1/participates/:id",
    GET_USER_PARTICIPANTS: "/api/v1/participates/user/:id",

    // My Profile
    GET_MY_PROFILE: "/api/v1/profile",
    UPDATE_MY_PROFILE: "/api/v1/profile/update",
    UPDATE_MY_PASSWORD: "/api/v1/profile/password",
    
};

export default Urls;