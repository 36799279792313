import { Input } from "antd";

const { Search } = Input;
const SearchBar = ({placeholder = 'Input your search', onSearch, style={}}) => {

    return (
        <Search
        style={style}
        placeholder={placeholder}
        allowClear
        onSearch={(value, _e, info) => onSearch(value)}
        />
    )
}

export default SearchBar;