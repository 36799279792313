import * as Types from "../types/EventType";
import * as ParticipantTypes from "../types/ParticipantType";

const initialState = {
    events: [],
    event: {}
};

const eventReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_EVENTS_SUCCESS: {
            return {
                ...state,
                events: action?.data
            }
        }
        case Types.GET_EVENT_SUCCESS: {
            let event = action?.data || {};
            //TODO: remove below line
            event.url = ["https://images.cnbctv18.com/wp-content/uploads/2023/08/cheems-moneycontrol.jpg", "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeO74Wf61GutnEyDOdroVrnIKgkmifF6bDhg&s","https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQeO74Wf61GutnEyDOdroVrnIKgkmifF6bDhg&s"]
            return {
                ...state,
                event
            }
        }
        case Types.CREATE_EVENT_SUCCESS:
            return {
                ...state,
                events: [...state.events, action.data]
            }
        case Types.GET_USER_EVENTS_SUCCESS: {
            return {
                ...state,
                events: action?.data
            }
        }
        case Types.GET_ORGANIZATION_EVENTS_SUCCESS: {
            return {
                ...state,
                events: action?.data
            }
        }
        case Types.DELETE_EVENT_SUCCESS: {
            return {
                ...state,
                events: state.events.filter(event => event.id !== action.data.id)
            }
        }
        case Types.UPDATE_EVENT_SUCCESS: {
            console.log(action.data)
            return {
                ...state,
                events: state.events.map(event => event.id === action.data.id ? action.data : event),
                event: action.data
            }
        }
        case Types.GET_SEARCH_EVENTS_SUCCESS: {
            return {
                ...state,
                events: action?.data
            }
        }

        default:
            return state;
    }
}

export default eventReducer;