import * as Types from '../types/EventCategoryType';
const initialState = {
    eventCategories: [],
    eventCategory: {},
}

const eventCategoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.GET_EVENT_CATEGORIES_SUCCESS:
            return {
                ...state,
                eventCategories: action.data,
            }
        default:
            return state;
    }
}

export default eventCategoryReducer;