import { call, put, takeLatest, fork, all } from "redux-saga/effects";
import * as Types from "../types/ProfileType";
import * as Actions from "../actions/profileAction";
import * as Services from "../services/profileService";
import * as Func from "../../utils/functions"
import { SUCCESS_CODE } from "../../constants/define";
import { notification } from "antd";
import ProfileRequest from "../mapping/Request/ProfileRequest";
import { USER_ROUTE } from "../../constants/routers";

function* getMyProfile() {
    yield takeLatest(
        Types.GET_MY_PROFILE_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.getMyProfile)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }
            yield put(Actions.getMyProfileSuccess(data.metadata))
        }, Func.errorHandle(Types.GET_MY_PROFILE_FAILURE))
    )
}

function* updateMyProfile(){
    yield takeLatest(
        Types.UPDATE_MY_PROFILE_REQUEST,
        Func.sagaWrapper(function* (action) {
            let sendData = new ProfileRequest(action?.params).exportUpdate();
            const data = yield call(Services.updateMyProfile, sendData)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.updateMyProfileSuccess(data.metadata))
            notification.success({message: "Update profile success!"})

        }, Func.errorHandle(Types.UPDATE_MY_PROFILE_FAILURE))
    )
}

function* updateMyPassword(){
    yield takeLatest(
        Types.UPDATE_MY_PASSWORD_REQUEST,
        Func.sagaWrapper(function* (action) {
            const data = yield call(Services.updateMyPassword, action?.params)

            if(!SUCCESS_CODE.includes(data?.status)){
                throw data;
            }

            yield put(Actions.updateMyPasswordSuccess(data.metadata))
            yield call(Func.redirectTo, USER_ROUTE.MY_PROFILE.sub_menu[0].path);



        }, Func.errorHandle(Types.UPDATE_MY_PASSWORD_FAILURE))
    )

}

export function* profileSaga() {
    yield all([
        fork(getMyProfile),
        fork(updateMyProfile),
        fork(updateMyPassword)
    ])
}