
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { useGetLogged } from "../data/hooks/authHook";
import { withRouter, Route } from "react-router-dom/cjs/react-router-dom";
import {AUTH_ROUTE} from "../constants/routers";
import PrivateContainer from "./PrivateContainer";
import history from "../utils/history";


const PrivateRoute = ({ component: Component, ...rest }) => {
    const logged = useGetLogged();
    return (
        <Route
        {...rest}
        render={(props) =>{
            if (!logged) {
                return <Redirect to={{pathname: AUTH_ROUTE.LOGIN.sub_menu[0].path, state: {from: history.location.pathname}}}/>;
            }
            //TODO: handle ACCESS DENIED 403 (create a new reduux action includes: ACCESS_FAILURE, ACCESS_SUCCESS, no ACCESS REQUEST)
            else{
                return (
                    <PrivateContainer path={rest?.path} group={rest?.group}>
                        <Component {...props} />
                    </PrivateContainer>
                )
            }
        }}


        />
    )
};

export default withRouter(PrivateRoute);