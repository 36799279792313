export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const GET_MY_PROFILE_REQUEST = "GET_MY_PROFILE_REQUEST";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_FAILURE = "GET_MY_PROFILE_FAILURE";

export const UPDATE_MY_PROFILE = "UPDATE_MY_PROFILE";
export const UPDATE_MY_PROFILE_REQUEST = "UPDATE_MY_PROFILE_REQUEST";
export const UPDATE_MY_PROFILE_SUCCESS = "UPDATE_MY_PROFILE_SUCCESS";
export const UPDATE_MY_PROFILE_FAILURE = "UPDATE_MY_PROFILE_FAILURE";

export const UPDATE_MY_PASSWORD = "UPDATE_MY_PASSWORD";
export const UPDATE_MY_PASSWORD_REQUEST = "UPDATE_MY_PASSWORD_REQUEST";
export const UPDATE_MY_PASSWORD_SUCCESS = "UPDATE_MY_PASSWORD_SUCCESS";
export const UPDATE_MY_PASSWORD_FAILURE = "UPDATE_MY_PASSWORD_FAILURE";